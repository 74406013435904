<template>
  <div class="dzs-aarin-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Buscar Conta</h4>
        </div>
        <div class="card-body row">
          <div class="col-md-3">
            <label class="control-label">Usuário / E-Mail / CPF / PersonId</label>
            <fg-input v-model="search.key"/>
          </div>
          <div class="col-md-2">
            <fg-input label="Ordenação Data">
              <el-select size="large"
                         placeholder="Selecionar"
                         class="select-default"
                         v-model="search.dateOrder">
                <el-option value="ASC"
                           class="select-default"
                           label="Crescente"/>
                <el-option value="DESC"
                           class="select-default"
                           label="Decrescente"/>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-1">
            <fg-input label="Exibir">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in [5,10,25,50]"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-2">
            <p-button type="primary" style="margin-top: 25px;"
                      @click="find">
              <i slot="label" class="nc-icon nc-check-2"></i>
              Buscar
            </p-button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column :min-width="60" label="Usuário">
                <template slot-scope="props">
                    <span>
                      <span><b>@{{props.row.username}}</b></span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="70" label="E-mail">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.email}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Nome">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.name}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Nome">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.name}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="48" label="Doc. Status">
                <template slot-scope="props">
                    <span>
                      <span>{{docStatusToLabel(props.row.documentStatus)}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="50" label="Criação">
                <template slot-scope="props">
                    <span>
                      <span>{{props.row.createdDate}}</span>
                    </span>
                </template>
              </el-table-column>
              <el-table-column :width="240" fixed="right" class-name="td-actions">
                <template slot-scope="props">
                  <p-button type="success" size="sm" icon title="Informações de conta" v-if="hasAnyAuthorization(['BO_AARIN_DETAILS']) && (props.row.accountNumber && props.row.accountNumber.length > 0)"
                            @click="showAccountInfo(props.row)">
                    <i class="fas fa-info-circle"></i>
                  </p-button>
                  <p-button type="info" size="sm" icon title="Documentos Adicionais" v-if="hasAnyAuthorization(['BO_AARIN_DETAILS'])"
                            @click="documents(props.row.username)">
                    <i class="fas fa-file-invoice"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">EXIBINDO DO {{pagination.fromNumber}} AO {{pagination.toNumber}}, DE
              {{pagination.totalNumber}} REGISTROS.</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.totalNumber"
                          :click="this.loadData">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="showModalAdicionalDescription">
      <h6 style="text-align: center"><i style="color: red !important;" class="fas fa-ban text-primary"></i></h6>
      <h6 style="text-align: center"><b>Descrição de Bloqueio</b></h6>
      <hr>
      <span>
        <card>
          <p v-if="showNotNullFields"> <span style="font-weight: lighter; color: red !important; font-size: small" class="text-muted">*obrigatório*</span></p>
          <p> <i class="fa-solid fa-envelope text-primary"></i> <span style="font-weight: bold"  class="text-muted"> Descrição: </span></p>
          <fg-input>
                  <el-select class="select-primary"
                             size="large"
                             placeholder="Selecione uma descrição"
                             v-model="statusDescription">
                    <el-option v-for="option in descriptions.list"
                               class="select-danger"
                               :value="option.status"
                               :label="option.description"
                               :key="option.status">
                    </el-option>
                  </el-select>
                </fg-input>
          <span>
            <p v-if="showNotNullFields"> <span style="font-weight: lighter; color: orange !important; font-size: small" class="text-muted">*obrigatório quando Descrição for "Outros"*</span></p>
            <p><i class="fa-solid fa-plus text-primary"></i> <span class="text-muted"> Informações adicionais: </span></p>
            <textarea v-model="description" class="form-control"  rows="3"></textarea>
          </span>
        </card>
      </span>
      <div style="text-align: center">
        <p-button type="danger" @click="saveNewBlockedHistory">
          <i slot="label" class="nc-icon nc-simple-remove"></i>
          Bloquear
        </p-button>
      </div>
    </modal>
  </div>
</template>
<script>
import Vue from 'vue'
import {Option, Select, Table, TableColumn} from 'element-ui'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import {failWs, postWs} from "src/ws.service"
import swal from 'sweetalert2'
import {isAuthorized, isSomeAuthorization} from "src/util/role.utils"
import Modal from "@/components/UIComponents/Modal.vue";
import checkbox from "@/components/UIComponents/Inputs/Checkbox.vue";

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
export default {
  computed: {
    checkbox() {
      return checkbox
    }
  },
  components: {
    Modal,
    PPagination
  },
  data() {
    return {
      search: {
        key: '',
        documentStatus: 'PENDING_VALIDATION',
        dateOrder: 'ASC',
        preApproved: null
      },
      tableData: [],
      descriptions: [],
      statusDescription: null,
      description: null,
      pagination: {
        perPage: 5,
        currentPage: 1,
        pageMaxNumber: 1,
        fromNumber: 0,
        toNumber: 0,
        totalNumber: 0
      },
      showModalAdicionalDescription: false,
      showNotNullFields: false,
      account: null,
    }
  },
  watch:{
    '$route': 'resetDefaultValues'
  },

  mounted() {
    this.loadData()
  },
  methods: {
    resetDefaultValues(){
      this.search.key = ''
      this.search.documentStatus = 'PENDING_VALIDATION'
      this.search.dateOrder = 'ASC'
      this.pagination.currentPage = 1
      this.pagination.perPage = 5
      this.pagination.pageMaxNumber = 1
      this.pagination.fromNumber = 0
      this.pagination.toNumber = 0
      this.pagination.totalNumber = 0
      this.tableData = [];
      this.descriptions = []
      this.loadDescriptionList()
      this.loadData()
    },
    find() {
      this.pagination.currentPage = 1
      this.loadData()
    },
    loadData() {
      postWs("/bo/aarin/list-account",
        true, null,
        {
          key: this.search.key,
          documentStatus: this.search.documentStatus,
          dateOrder: this.search.dateOrder,
          preApproved: this.search.preApproved,
          pageNumber: (this.pagination.currentPage - 1),
          pageSize: this.pagination.perPage
        }, this.successLoadData,
        failWs)
      return this.pagination.currentPage
    },
    successLoadData(response) {
      this.tableData = response.data.accounts
      this.pagination.totalNumber = response.data.totalNumber
      this.pagination.pageMaxNumber = response.data.pageMaxNumber
      if (this.pagination.totalNumber > 0) {
        this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
        this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
      } else {
        this.pagination.fromNumber = 0
        this.pagination.toNumber = 0
      }
    },
    documents(username) {
      this.$router.push({name: 'BoAarinDocuments', params: {username}})
    },
    showAccountInfo(account) {
      swal({
        title: 'Dados Bancários',
        html: `
        <p><strong>Número de Conta:</strong> ${account.accountNumber}</p>
        <p><strong>Agência:</strong> ${account.agencyNumber}</p>
        <p><strong>Banco:</strong> ${account.bankCode}</p>
        <p><strong>Tipo de conta:</strong> ${account.accountType}</p>
        <hr>
        <p><strong>PersonId:</strong> ${account.aarinPersonId}</p>
        `,
        buttonsStyling: true,
        confirmButtonClass: 'btn btn-info btn-fill',
        width: '40rem'
      }).catch(swal.noop)
    },
    restoreDefaultValuesBlockedUser(){
      this.statusDescription = null
      this.description = null
    },
    saveNewBlockedHistory(){
      if(this.account !== null && this.statusDescription !== null && this.statusDescription !== "OUTROS"){
        this.callNonlockedWs(this.account, false , this.statusDescription, this.description)
        this.showModalAdicionalDescription = false
        this.restoreDefaultValuesBlockedUser()
      }else if(this.description !== null && this.statusDescription === "OUTROS") {
        this.callNonlockedWs(this.account, false , this.statusDescription, this.description)
        this.showModalAdicionalDescription = false
        this.restoreDefaultValuesBlockedUser()
      }else{
        swal({
          type: "error",
          title: "Os campos obrigatórios devem ser preenchidos"
        })
        this.showNotNullFields = true
      }
    },
    callNonlockedWs(account, status, statusDescription, description) {
      postWs("/bo/auth/update-nonlocked",
        true, null,
        {username: account.username, status: status},
        function () {
          account.nonlocked = status
        }, failWs)
      if(statusDescription){
        postWs("/bo/auth/new-history-blocked-user" , true, null,
          {username: account.username, status: statusDescription, description: description},
          function () {}
          ,failWs)
      }
    },
    docStatusToLabel(documentStatus) {
      const statusLabels = {
        null: 'PE*',
        'PENDING_DOCUMENT': 'Documento Pendente',
        'PENDING_VALIDATION': 'Validação Pendente',
        'APPROVED': 'Aprovado',
        'REJECTED_AT_PRE_APPROVAL': 'Rejeitado em Pré-Aprovação',
        'PENDING_KYC': 'KYC Pendente',
        'PROCESSING_KYC': 'KYC em Processamento',
        'CANCELLED_KYC': 'KYC Cancelado',
        'MANUAL_ANALYSIS_KYC': 'KYC em Análise Manual',
        'REJECTED_KYC': 'KYC Rejeitado'
      };

      return statusLabels[documentStatus] ?? '??';
    },
    openWindow(url) {
      try {
        window.open(url)
      } catch (ex) {
        swal({
          title: 'Aviso!',
          text: "Para a exibição do conteúdo, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
          buttonsStyling: true,
          confirmButtonClass: 'btn btn-warning btn-fill',
          type: 'warning'
        })
      }
    },
    hasAnyAuthorization(roles){
      return isSomeAuthorization(roles);
    }
  }
}
</script>
<style lang="scss">
.dzs-acc-search {
  .el-table .td-actions {
    button.btn {
      margin-right: 5px;
    }
  }

  .input-group .form-control {
    padding-left: 2px !important;
  }

  .input-group .input-group-prepend .input-group-text {
    padding: 5px 0 5px 10px !important;
    font-size: 14px !important;
  }

  .cell {
    font-size: 12px !important;
  }

  th {
    color: black !important;
  }
}
.dzs-aarin-search .el-table .td-actions button.btn {
  margin-right: 0px;
}
</style>
