<template>
  <div class="dzs-operation-limit">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Limites do Usuário</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-5">
              <fg-input :disabled="formDisabled"
                        label="Usuário"
                        type="text"
                        v-model="username">
              </fg-input>
            </div>
            <div class="col-md-3">
              <fg-input label="Tipo de Pessoa">
                <el-select :disabled="formDisabled"
                           placeholder="Selecionar"
                           size="large"
                           v-model="personType">
                  <el-option class="select-success"
                             label="Pessoa Física"
                             value="NATURAL_PERSON"/>
                  <el-option class="select-info"
                             label="Pessoa Jurídica"
                             value="LEGAL_PERSON"/>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-4">
              <fg-input :disabled="formDisabled"
                        :label="personType=='NATURAL_PERSON'?'CPF':'CNPJ'"
                        name="CPF/CNPJ"
                        type="tel"
                        v-mask="['###.###.###-##','##.###.###/####-##']"
                        v-model="socialNumber">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <fg-input :disabled="formDisabled"
                        label="Nome Completo"
                        name="Nome Completo"
                        type="text"
                        v-model="personName">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div v-if="personType == 'LEGAL_PERSON'">-->
              <div class="col-md-12">
                <fg-input :disabled="formDisabled"
                          label="Razão Social"
                          name="Razão Social"
                          type="text"
                          v-model="companyName">
                </fg-input>
              </div>
            </div>
          </div>
          <div class="card-body row">
            <div class="col-sm-12 mt-2">
              <el-table :data="tableData"
                        :row-class-name="customLimitClassStyle"
                        class="table-striped"
                        style="width: 85%">
                <el-table-column :min-width="60" label="Limite">
                  <template slot-scope="props">
                    <span>
                      <span>
                        <b>{{opNameToLabel(props.row.limit)}}</b>
                      </span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="25" label="Valor">
                  <template slot-scope="props">
                    <span>
                      <fg-input v-model="props.row.value" v-on:change="handleChangeValue(props)"
                                v-on:input="handleChangeValue(props)"/>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="30" label="Tipo Op.">
                  <template slot-scope="props">
                    <span>
                      <span>{{opStatusToLabel(props.row.operationNature)}}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :width="120" class-name="td-actions" fixed="right" label="Ação">
                  <template slot-scope="props">
                  <span style="margin-left: 5px" v-if="getEditedState(props.$index) && hasAuthorization('BO_SET_USER_OP_LIMIT')">
                    <p-button @click="save(props)" icon size="sm" title="Salvar" type="danger">
                      <i class="fas fa-edit"></i>
                    </p-button>
                  </span>
                    <span style="margin-left: 5px" v-if="props.row.customLimit && hasAuthorization('BO_DELETE_USER_OP_LIMIT')">
                      <p-button @click="remove(props)" icon size="sm" title="Restaurar Limite Padrão" type="warning">
                        <i class="fas fa-unlink"></i>
                      </p-button>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-info">
              <i class="fas fa-circle" :style="'color: yellow'"></i>
              <span>Limites personalizados</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Taxas Gateway</h4>
        </div>
        <div class="card-body" v-show="!isGatewayPlan">
          <div class="row">
            <div class="col-sm-12">
              <p>Usuário sem plano de Gateway cadastrado.</p>
            </div>
          </div>
        </div>
        <div class="card-body" v-show="isGatewayPlan">
          <div class="row">
            <div class="col-sm-12 row">
              <div class="col-sm-2">
                <label>Taxa de Cartão Link</label>
                <money class="form-control input-lg"
                       v-model="gatewayFee.cardFee"
                       v-bind="fee"
                       disabled="true">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Taxa de Cartão Gateway</label>
                <money class="form-control input-lg"
                       v-model="gatewayFee.cardFeeCheckout"
                       v-bind="fee"
                       disabled="true">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Tipo de Antecipação</label>
                <input class="form-control input-lg"
                       v-model="gatewayFee.firstInstallmentType"
                       disabled="true">
                </input>
              </div>
            </div>
            <div class="col-sm-12 row" style="margin-top: 10px">
              <div class="col-sm-2">
                <label>Taxa Pix Link</label>
                <money class="form-control input-lg"
                       v-model="gatewayFee.pixFee"
                       v-bind="fee">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Taxa Percentual Pix Gateway</label>
                <money class="form-control input-lg"
                       v-model="gatewayFee.pixFeeCheckout"
                       v-bind="fee">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Taxa Valor Pix Gateway</label>
                <money class="form-control input-lg"
                       v-model="gatewayFee.pixRateCheckout"
                       v-bind="money">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Taxa Mínima Pix Gateway</label>
                <money class="form-control input-lg"
                       v-model="gatewayFee.pixFeeMinCheckout"
                       v-bind="money">
                </money>
              </div>
            </div>
            <div class="col-sm-12 row" style="margin-top: 10px">
              <div class="col-sm-2">
                <label>Taxa Boleto Link</label>
                <money class="form-control input-lg"
                       v-model="gatewayFee.boletoFee"
                       v-bind="fee">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Taxa Percentual Boleto Gateway</label>
                <money class="form-control input-lg"
                       v-model="gatewayFee.boletoFeeCheckout"
                       v-bind="fee">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Taxa Valor Boleto Gateway</label>
                <money class="form-control input-lg"
                       v-model="gatewayFee.boletoRateCheckout"
                       v-bind="money">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Taxa Mínima Boleto Gateway</label>
                <money class="form-control input-lg"
                       v-model="gatewayFee.boletoFeeMinCheckout"
                       v-bind="money">
                </money>
              </div>
            </div>
            <div class="col-sm-12 row" style="margin-top: 10px">
              <div class="col-sm-2">
                <label>Taxa Percentual Pix Cash-out</label>
                <money class="form-control input-lg"
                       v-model="gatewayFee.pixCashoutFeeCheckout"
                       v-bind="fee">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Taxa Valor Pix Cash-out</label>
                <money class="form-control input-lg"
                       v-model="gatewayFee.pixCashoutRateCheckout"
                       v-bind="money">
                </money>
              </div>
              <div class="col-sm-2">
                <label>Taxa Mínima Pix Cash-out</label>
                <money class="form-control input-lg"
                       v-model="gatewayFee.pixCashoutFeeMinCheckout"
                       v-bind="money">
                </money>
              </div>
            </div>
            <div class="col-sm-12 row" style="margin-top: 10px">
              <div class="row col-sm-12">
                <div class="col-sm-2">
                  <fg-input label="Parceiros">
                    <el-select v-model="gatewayFee.partnerPixUser.username">
                      <el-option value="" label="Nenhum" selected></el-option>
                      <el-option v-for="item in partnerPixUsers" :value="item.username" :key="item.username" :label="item.username"></el-option>
                    </el-select>
                  </fg-input>
                </div>
              </div>
              <div class="col-sm-12 row">
                <div class="col-sm-2">
                  <label>Taxa mínima</label>
                  <money class="form-control input-lg"
                         v-model="gatewayFee.partnerPixUser.minimumFee"
                         v-bind="money"
                         :disabled="gatewayFee.partnerPixUser.username.length === 0">
                  </money>
                </div>
                <div class="col-sm-2">
                  <label>Porcentagem  de divisão</label>
                  <money class="form-control input-lg"
                         v-model="gatewayFee.partnerPixUser.percentageSplitPartner"
                         v-bind="fee"
                         :disabled="gatewayFee.partnerPixUser.username.length === 0">
                  </money>
                </div>
                <div class="col-sm-2">
                  <label>Valor dividido</label>
                  <money class="form-control input-lg"
                         v-model="gatewayFee.partnerPixUser.valueSplitPartner"
                         v-bind="money"
                         :disabled="gatewayFee.partnerPixUser.username.length === 0">
                  </money>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <p-button type="success" style="margin-top: 25px"
                        @click="saveGatewayFee">
                <i slot="label" class="nc-icon nc-check-2"></i>
                Salvar Taxas Gateway
              </p-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 card" v-if="hasAuthorization('BO_BLOCK_FUNDS')">
        <div class="card-header">
          <h4>Bloqueio de Saldo</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-2">
              <label>Saldo Bloqueado*</label>
              <money class="form-control input-lg"
                     v-model="blockedFunds.totalBlocked"
                     v-bind="money">
              </money>
            </div>
            <div class="col-sm-2">
              <label>Saldo Disponível*</label>
              <money class="form-control input-lg"
                     disabled
                     v-model="blockedFunds.availableFunds"
                     v-bind="money">
              </money>
            </div>
            <div class="col-sm-2">
              <label>Saldo Total</label>
              <money class="form-control input-lg"
                     disabled
                     v-model="totalAvailableFunds"
                     v-bind="money">
              </money>
            </div>

            <div class="col-md-4">
              <p-button type="success" style="margin-top: 25px"
                        @click="saveBlockedFunds">
                <i slot="label" class="nc-icon nc-check-2"></i>
                Salvar Bloqueio de Saldo
              </p-button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <span class="text-info">*Dados referentes ao momento da consulta</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Bloqueio de Cash Out</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 text-success" v-if="!isBlockedCashOut">
              <p>Usuário sem bloqueio de transações de saída.</p>
            </div>
            <div class="col-sm-12 text-danger" v-if="isBlockedCashOut">
              <p>Usuário com bloqueio de transações de saída.</p>
            </div>
          </div>
          <div class="row" v-if="hasAuthorization('BO_BLOCK_OUT_TRANSACTIONS')">
            <div class="col-md-4">
              <p-button :type="isBlockedCashOut ? 'success' : 'warning'" style="margin-top: 5px"
                        @click="blockCashOut">
                <i slot="label" class="nc-icon nc-check-2"></i>
                <span v-if="isBlockedCashOut">
                Desbloquear
              </span>
                <span v-else>
                Bloquear
              </span>
              </p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {failWs, postWs} from "src/ws.service"
  import swal from 'sweetalert2'
  import {isAuthorized} from "../../../../../util/role.utils";

  Vue.use(Table);
  Vue.use(TableColumn);
  Vue.use(Select);
  Vue.use(Option);
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        tableData: [],
        editStatus: [],
        username: null,
        personName: null,
        socialNumber: null,
        companyName: null,
        personType: null,
        formDisabled: false,
        isGatewayPlan: false,
        gatewayFee: {
          username: '',
          cardFee: 0,
          boletoFee: 0,
          pixFee: 0,
          firstInstallmentType: '',
          cardFeeCheckout: 0,
          pixFeeCheckout: 0,
          pixRateCheckout: 0,
          pixFeeMinCheckout: 0,
          boletoFeeCheckout: 0,
          boletoRateCheckout: 0,
          boletoFeeMinCheckout: 0,
          pixCashoutFeeCheckout: 0,
          pixCashoutRateCheckout: 0,
          pixCashoutFeeMinCheckout: 0,
          partnerPixUser: {
            username: '',
            minimumFee: 0,
            percentageSplitPartner: 0,
            valueSplitPartner: 0
          },
        },
        partnerPixUsers: [],
        isBlockedCashOut: false,
        blockedFunds: {
          username: '',
          totalBlocked: '',
          availableFunds: '',
        },
        totalAvailableFunds:0,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
        fee: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: ' %',
          precision: 2,
          masked: false
        },
      }
    },
    created() {
      this.username = this.$route.params.username
      this.blockedFunds.username = this.username
      this.loadData()
    },
    methods: {
      customLimitClassStyle({row, index}) {
        console.debug(row)
        return row.customLimit !== null && row.customLimit ? 'table-warning' : ''
      },
      getEditedState(index) {
        return this.editStatus[index].edited
      },
      handleChangeValue(props) {
        this.editStatus[props.$index].edited = true;
      },
      blockCashOut(){
        postWs("/bo/acc/block-out-transactions",
          true,
          null,
          {username: this.username, block: !this.isBlockedCashOut},
          this.blockCashoutSucess,
          error => {
            swal({
                   title: 'Erro',
                   text: 'Falha ao alterar bloqueio de cash out!',
                   type: 'warning',
                 })
          });
      },
      blockCashoutSucess(){
        this.isBlockedCashOut = !this.isBlockedCashOut
      },
      loadData() {
        postWs("/op-limit/list-user-op-limits",
          true,
          {'username': this.username},
          null,
          this.successLoadData,
          failWs);
        if(isAuthorized('BO_BLOCK_FUNDS')) {
          postWs("/op-limit/get-blocked-funds",
            true,
            null,
            {'username': this.username},
            this.loadBlockedFunds,
            (error) => {
              swal({
                title: 'Ops!',
                text: "Falha ao carregar saldo bloqueado para este usuário",
                type: 'error',
                timer: 5000,
                confirmButtonClass: 'btn btn-warning btn-fill',
              })
            });
        }
      },
      successLoadData(response) {
        this.tableData = response.data.list;
        this.editStatus = this.tableData.map(row => {
          return {edited: false}
        })

        this.personName = response.data.personName;
        this.socialNumber = response.data.socialNumber;
        this.companyName = response.data.companyName;
        this.personType = this.opPersonToLabel(response.data.personType);
        this.formDisabled = true
        this.isBlockedCashOut = response.data.isBlockedCashOut

        this.isGatewayPlan = response.data.isGatewayPlan;
        if(this.isGatewayPlan == true) {
          this.gatewayFee.username = response.data.gatewayPlan.username;
          this.gatewayFee.cardFee = response.data.gatewayPlan.cardFee;
          this.gatewayFee.boletoFee = response.data.gatewayPlan.boletoFee;
          this.gatewayFee.pixFee = response.data.gatewayPlan.pixFee;
          this.gatewayFee.firstInstallmentType = response.data.gatewayPlan.firstInstallmentType;
          this.gatewayFee.cardFeeCheckout = response.data.gatewayPlan.cardFeeCheckout;
          this.gatewayFee.pixFeeCheckout = response.data.gatewayPlan.pixFeeCheckout;
          this.gatewayFee.pixRateCheckout = response.data.gatewayPlan.pixRateCheckout;
          this.gatewayFee.pixFeeMinCheckout = response.data.gatewayPlan.pixFeeMinCheckout;
          this.gatewayFee.boletoFeeCheckout = response.data.gatewayPlan.boletoFeeCheckout;
          this.gatewayFee.boletoRateCheckout = response.data.gatewayPlan.boletoRateCheckout;
          this.gatewayFee.boletoFeeMinCheckout = response.data.gatewayPlan.boletoFeeMinCheckout;
          this.gatewayFee.pixCashoutFeeCheckout = response.data.gatewayPlan.pixCashoutFeeCheckout;
          this.gatewayFee.pixCashoutRateCheckout = response.data.gatewayPlan.pixCashoutRateCheckout;
          this.gatewayFee.pixCashoutFeeMinCheckout = response.data.gatewayPlan.pixCashoutFeeMinCheckout;
        }
        this.partnerPixUsers = response.data.partnerPixUsers
      },
      loadBlockedFunds(response) {
        this.blockedFunds = response.data;
        this.blockedFunds.username = this.username
        this.totalAvailableFunds = this.blockedFunds.availableFunds + this.blockedFunds.totalBlocked
      },
      save(operationLimitScope) {
        swal({
          title: 'Alterar Limite',
          text: "Você deseja realmente salvar as alterações para o limite " + operationLimitScope.row.limit + "?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'sim',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'CANCELAR',
          cancelButtonColor: '#ef8157'
        }).then(() => {
          postWs("/op-limit/set-op-user-limit", true,
            null, {
              username: this.username,
              limit: operationLimitScope.row.limit,
              value: operationLimitScope.row.value
            },
            (response) => {
              this.editStatus[operationLimitScope.$index].edited = false
              operationLimitScope.row.customLimit = true
            }, failWs)
        })
      },
      saveBlockedFunds() {
        if (this.blockedFunds.totalBlocked < 0) {
          swal({
            title: 'Valor inválido',
            text: "O saldo a bloquear deve ser maior ou igual a zero!",
            type: 'error',
            timer: 5000,
            confirmButtonClass: 'btn btn-warning btn-fill',
          })
          return;
        }

        swal({
          title: 'Alterar Saldo Bloqueado',
          text: "Você deseja realmente salvar as alterações de bloqueio de saldo?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'sim',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'CANCELAR',
          cancelButtonColor: '#ef8157'
        }).then(() => {
          postWs("/op-limit/block-funds", true,
            null, this.blockedFunds,
            (response) => {
              postWs("/op-limit/get-blocked-funds",true,null, {'username': this.username},
                this.loadBlockedFunds,failWs);
              swal({
                title: 'Sucesso',
                text: "Bloqueio alterado com sucesso!",
                type: 'success',
              })
            }, this.failSaveBlockedFunds)
        })
      },
      failSaveBlockedFunds(error) {
        let text = 'Erro ao salvar bloqueio de saldo, tente novamente!'
        swal({
          title: 'Erro',
          text: text,
          type: 'warning',
        })
      },
      remove(operationLimitScope) {
        swal({
          title: 'Restaurar Limite Padrão',
          text: "Você deseja realmente restaurar o limite " + operationLimitScope.row.limit + " para o valor padrão?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'sim',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'CANCELAR',
          cancelButtonColor: '#ef8157'
        }).then(() => {
          postWs("/op-limit/delete-op-user-limit", true,
            null, {
              username: this.username,
              limit: operationLimitScope.row.limit,
              value: null
            },
            (response) => {
              this.loadData()
            }, failWs)
        })
      },
      opStatusToLabel(opType) {
        if (opType === 'SOLICITATION') {
          return 'SOLICITAÇÃO'
        } else if (opType === 'OUT') {
          return 'SAÍDA'
        } else if (opType === 'IN') {
          return 'ENTRADA'
        }
        return '??'
      },
      opNameToLabel(limit) {
        if (limit === 'BOLETO_MAX_UNPAID_DUEDATE') {
          return 'Quantidade de boletos não pagos:'
        } else if (limit === 'BOLETO_MAX_VALUE') {
          return 'Valor máximo por boleto (R$):'
        } else if (limit === 'BOLETO_MAX_VALUE_MONTHLY') {
          return 'Valor máximo de boleto mês (R$):'
        } else if (limit === 'BOLETO_MIN_VALUE') {
          return 'Valor mínimo por boleto (R$):'
        } else if (limit === 'PIX_FEE') {
          return 'Taxa de PIX (%):'
        } else if (limit === 'PIX_PAYMENT_FEE') {
          return 'Taxa de PIX de saída (R$):'
        } else if (limit === 'PIX_PAYMENT_MAX_VALUE') {
          return 'Valor máximo de envio por PIX (R$):'
        } else if (limit === 'PIX_PAYMENT_MAX_VALUE_DAILY') {
          return 'Valor máximo de envio de PIX por dia (R$):'
        } else if (limit === 'PIX_PAYMENT_MONTHLY_FREE') {
          return 'Quantidade de envio de PIX gratuitos no mês: '
        } else if (limit === 'TED_MAX_VALUE') {
          return 'Valor máximo por TED (R$):'
        } else if (limit === 'TED_MAX_VALUE_DAILY') {
          return 'Valor máximo de TED por dia (R$):'
        } else if (limit === 'TED_MAX_VALUE_MONTHLY') {
          return 'Valor máximo de TED por mês (R$):'
        } else if (limit === 'BOLETO_PAYMENT_MAX_VALUE') {
          return 'Valor máximo de pagamento de um boleto (R$):'
        } else if (limit === 'BOLETO_PAYMENT_MAX_VALUE_DAILY') {
          return 'Valor máximo de pag. de boleto por dia (R$):'
        } else if (limit === 'CREDIT_CARD_VIRTUAL_MAX_ITEMS') {
          return 'Quantidade máxima de cartões virtuais para emissão:'
        } else if (limit === 'CREDIT_CARD_PHYSICAL_MAX_ITEMS') {
          return 'Quantidade máxima de cartões físicos para emissão:'
        } else if (limit === 'CREDIT_CARD_RECHARGE_MAX_DAILY') {
          return 'Valor máximo de recarga de cartão por dia (R$):'
        } else if (limit === 'CREDIT_CARD_RECHARGE_MAX_MONTHLY') {
          return 'Valor máximo de recarga de cartão por mês (R$):'
        } else if (limit === 'PAYMENT_LINK_MAX_VALUE') {
          return 'Valor máximo de um link de pagamento:'
        } else if (limit === 'BOLETO_PAYMENT_MAX_DAILY') {
          return 'Valor máximo de pagamento de boletos por dia:'
        } else if (limit === 'BOLETO_PAYMENT_MAX_MONTHLY') {
          return 'Valor máximo de pagamento de boletos por mês:'
        } else if (limit === 'PIX_IN_GATEWAY') {
          return 'Valor máximo de pix IN pelo Gateway:'
        } else if (limit === 'PIX_IN_BANKING') {
          return 'Valor máximo de pix IN pelo Banking:'
        } else {
          return limit
        }
      },
      opPersonToLabel(person) {
        if (person === 'NATURAL_PERSON') {
          return 'Pessoa Física'
        } else if (person === 'LEGAL_PERSON') {
          return 'Pessoa Jurídica'
        }
        return '??'
      },
      hasAuthorization(role){
        return isAuthorized(role);
      },
      saveGatewayFee() {
        swal({
          title: 'Alterar Limite',
          text: "Você deseja realmente salvar as alterações de Taxas de Gateway?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'sim',
          confirmButtonColor: '#00c390',
          cancelButtonText: 'CANCELAR',
          cancelButtonColor: '#ef8157'
        }).then(() => {
          postWs("/op-limit/update-gateway-plan", true,
            null, this.gatewayFee,
            (response) => {
              swal({
                title: 'Sucesso',
                text: "Taxas de Gateway alteradas com sucesso!",
                type: 'success',
              })
            }, this.failSaveGatewayFee)
        })
      },
      failSaveGatewayFee(error) {
        let text = 'Erro ao salvar taxas de gateway, tente novamente!'
        if(error.response.status == '428') {
          text = 'As taxas de transação e de antecipação somadas não podem ser menores do que a taxa custo de crédito à vista!'
        }
        swal({
          title: 'Erro',
          text: text,
          type: 'warning',
        })
      },
    },
    watch: {
      'gatewayFee.partnerPixUser.username'(value) {
        if(!value) {
          this.gatewayFee.partnerPixUser.minimumFee = ''
          this.gatewayFee.partnerPixUser.percentageSplitPartner = ''
          this.gatewayFee.partnerPixUser.valueSplitPartner = ''
          return
        }
        const selectedUser = this.partnerPixUsers.filter(el => el.username === value)[0]
        this.gatewayFee.partnerPixUser.minimumFee = selectedUser.minimumFee
        this.gatewayFee.partnerPixUser.percentageSplitPartner = selectedUser.percentageSplitPartner
        this.gatewayFee.partnerPixUser.valueSplitPartner = selectedUser.valueSplitPartner
      }
    }
  }
</script>
<style lang="scss">
  .dzs-operation-limit {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 12px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }

    .el-input__inner {
      background-color: #FFF !important;
    }

    .el-input__inner[disabled] {
      background-color: #a8a8a8 !important;
    }

    .el-input__inner[readonly] {
      background-color: #ffffff !important;
    }
  }

</style>
